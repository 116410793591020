import React from 'react';
import { Link } from 'react-router-dom';
import { DoyenBrand } from './brand/DoyenBrand';

export function PageFooter() {
  return (
    <footer className="bg-gray-50 border-t border-gray-200 py-8 mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-sm font-medium text-gray-500">
            {/* Column 1: Products */}
            <div className="flex flex-col items-center space-y-2">
              <Link to="/products/erp-migration" className="hover:text-gray-900">
                ERP Migration
              </Link>
              <Link to="/products/billing-migration" className="hover:text-gray-900">
                Billing Migration
              </Link>
              <Link to="/products/financial-data-migration" className="hover:text-gray-900">
                Financial Data Migration
              </Link>
              <Link to="/products/security" className="hover:text-gray-900">
                Security
              </Link>
            </div>

            {/* Column 2: Company */}
            <div className="flex flex-col items-center space-y-2">
              <Link to="/blog" className="hover:text-gray-900">
                Blog
              </Link>
              <Link to="/about" className="hover:text-gray-900">
                About Us
              </Link>
              <a 
                href="https://app.dover.com/jobs/doyen-ai" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="hover:text-gray-900"
              >
                Careers
              </a>
              <Link to="/contact" className="hover:text-gray-900">
                Contact
              </Link>
            </div>

            {/* Column 3: Legal */}
            <div className="flex flex-col items-center space-y-2">
              <Link to="/status" className="hover:text-gray-900">
                Status
              </Link>
              <Link to="/privacy-policy" className="hover:text-gray-900">
                Privacy Policy
              </Link>
              <Link to="/terms-of-service" className="hover:text-gray-900">
                Terms of Service
              </Link>
            </div>
          </div>
          <div className="text-sm text-gray-400">
            © {new Date().getFullYear()} <DoyenBrand />. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}