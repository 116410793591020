import React from 'react';
import { SEO } from '../components/SEO';

export function PrivacyPolicy() {
  return (
    <div className="min-h-screen pt-32 pb-16 bg-gray-50">
      <SEO
        title="Privacy Policy | Doyen AI"
        description="Learn about how Doyen AI collects, uses, and protects your personal information"
        url={`${window.location.origin}/privacy-policy`}
      />
      
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
        <div className="prose prose-lg max-w-none">
          <p className="text-sm text-gray-500 mb-8">Effective Date: April 8, 2025</p>
          
          <p className="mb-8">
            Doyen AI, Corp. ("Doyen," "we," or "us") is committed to protecting your privacy. This Privacy Policy describes how we collect, use, and protect personal information through our Services.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">1. Information We Collect</h2>
          <p>We collect information in the following ways:</p>

          <h3 className="text-xl font-bold mt-6 mb-3">a. Information You Provide</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Account information such as name, business email address, company name, and user role</li>
            <li>Support requests or inquiries</li>
          </ul>

          <h3 className="text-xl font-bold mt-6 mb-3">b. Information from Use of Services</h3>
          <ul className="list-disc ml-6 mb-4">
            <li>Log and usage data (e.g., access times, API activity, error logs)</li>
            <li>Metadata associated with customer data processing</li>
          </ul>

          <h3 className="text-xl font-bold mt-6 mb-3">c. Customer Data</h3>
          <p>Customers may submit or store personal information (PII) in the course of using our Services. We process this data only as a service provider and according to our agreements with customers.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">2. How We Use Information</h2>
          <p>We use collected information to:</p>
          <ul className="list-disc ml-6 mb-4">
            <li>Provide, maintain, and improve the Services</li>
            <li>Respond to inquiries and provide customer support</li>
            <li>Ensure platform security and reliability</li>
            <li>Comply with legal obligations</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8 mb-4">3. Data Sharing and Disclosure</h2>
          <p>We do not sell personal information. We may share information with:</p>
          <ul className="list-disc ml-6 mb-4">
            <li>Third-party service providers who perform services on our behalf (e.g., hosting, logging)</li>
            <li>Legal authorities, if required by law or to protect our rights</li>
          </ul>
          <p>All vendors are subject to appropriate confidentiality and security obligations.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">4. Data Security</h2>
          <p>We maintain administrative, technical, and physical safeguards designed to protect the confidentiality, integrity, and availability of your information.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">5. Data Retention</h2>
          <p>We retain data as long as needed to provide the Services or fulfill legal obligations. We may delete inactive accounts or data after a period of time specified in our agreements.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">6. Your Rights</h2>
          <p>If you are an authorized user and wish to access, correct, or delete your personal information, please contact us at privacy@doyen.com. We will process requests in accordance with applicable laws and contractual obligations.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">7. Cookies and Tracking</h2>
          <p>We do not use cookies or tracking technologies on our public website.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">8. International Users</h2>
          <p>Our Services are operated in the United States. If you access them from outside the U.S., you consent to the processing and transfer of your data in accordance with this policy.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">9. Updates to This Policy</h2>
          <p>We may update this Privacy Policy periodically. Changes will be posted on this page with an updated "Effective Date."</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">10. Contact</h2>
          <p>If you have questions about this Privacy Policy or our data practices, please contact us at privacy@doyen.com.</p>
        </div>
      </div>
    </div>
  );
}