import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { PageFooter } from './components/PageFooter';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Blog } from './pages/Blog';
import { BlogPost } from './pages/BlogPost';
import { Contact } from './pages/Contact';
import { Partners } from './pages/Partners';
import { BillingMigration } from './pages/products/BillingMigration';
import { ErpMigration } from './pages/products/ErpMigration';
import { FinancialDataMigration } from './pages/products/FinancialDataMigration';
import { Security } from './pages/products/Security';
import { Status } from './pages/Status';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService';

function App() {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/products/erp-migration" element={<ErpMigration />} />
            <Route path="/products/billing-migration" element={<BillingMigration />} />
            <Route path="/products/financial-data-migration" element={<FinancialDataMigration />} />
            <Route path="/products/security" element={<Security />} />
            <Route path="/status" element={<Status />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </main>
        <PageFooter />
      </div>
    </Router>
  );
}

export default App;