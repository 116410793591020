import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, ChevronDown } from 'lucide-react';
import { DoyenBrand } from './brand/DoyenBrand';

export function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsProductsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleProducts = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  return (
    <nav className="fixed w-full bg-white/90 backdrop-blur-sm z-50 border-b border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <Link to="/" className="text-xl">
            <DoyenBrand />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden sm:flex items-center space-x-8">
            <div className="relative" ref={dropdownRef}>
              <button
                className="text-gray-600 hover:text-black transition-colors flex items-center"
                onClick={toggleProducts}
              >
                Use Cases
                <ChevronDown className={`ml-1 h-4 w-4 transition-transform ${isProductsOpen ? 'rotate-180' : ''}`} />
              </button>
              
              {isProductsOpen && (
                <div className="absolute left-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1">
                  <Link
                    to="/products/erp-migration"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => setIsProductsOpen(false)}
                  >
                    ERP Migration
                  </Link>
                  <Link
                    to="/products/billing-migration"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => setIsProductsOpen(false)}
                  >
                    Billing Migration
                  </Link>
                  <Link
                    to="/products/financial-data-migration"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => setIsProductsOpen(false)}
                  >
                    Financial Data Migration
                  </Link>
                </div>
              )}
            </div>
            <Link to="/products/security" className="text-gray-600 hover:text-black transition-colors">
              Security
            </Link>
            <Link to="/partners" className="text-gray-600 hover:text-black transition-colors">
              Partners
            </Link>
            <Link to="/blog" className="text-gray-600 hover:text-black transition-colors">
              Blog
            </Link>
            <Link to="/contact" className="text-gray-600 hover:text-black transition-colors">
              Contact
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={toggleMenu}
            className="sm:hidden p-2 rounded-md text-gray-600 hover:text-black hover:bg-gray-100"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="sm:hidden py-4 border-t border-gray-100">
            <div className="flex flex-col space-y-4">
              <div className="space-y-2">
                <div className="font-medium px-2 text-gray-900">Use Cases</div>
                <Link
                  to="/products/erp-migration"
                  className="block pl-4 py-1 text-gray-600 hover:text-black"
                  onClick={() => setIsMenuOpen(false)}
                >
                  ERP Migration
                </Link>
                <Link
                  to="/products/billing-migration"
                  className="block pl-4 py-1 text-gray-600 hover:text-black"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Billing Migration
                </Link>
                <Link
                  to="/products/financial-data-migration"
                  className="block pl-4 py-1 text-gray-600 hover:text-black"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Financial Data Migration
                </Link>
              </div>
              <Link 
                to="/products/security" 
                className="text-gray-600 hover:text-black transition-colors px-2 py-1 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                Security
              </Link>
              <Link 
                to="/partners" 
                className="text-gray-600 hover:text-black transition-colors px-2 py-1 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                Partners
              </Link>
              <Link 
                to="/blog" 
                className="text-gray-600 hover:text-black transition-colors px-2 py-1 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                Blog
              </Link>
              <Link 
                to="/contact" 
                className="text-gray-600 hover:text-black transition-colors px-2 py-1 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                Contact
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}