import React from 'react';
import { Database, ArrowRightLeft, RefreshCw, Shield } from 'lucide-react';
import { SEO } from '../../components/SEO';

export function BillingMigration() {
  return (
    <div className="min-h-screen">
      <SEO
        title="Billing Migration | Doyen AI"
        description="Seamlessly migrate your billing data with AI-assisted automation"
        url={`${window.location.origin}/products/billing-migration`}
      />

      {/* Header Section - Dark */}
      <section className="bg-black text-white pt-32 pb-24">
        <div className="text-center">
          <h1 className="text-5xl font-bold mb-6">Migrate Billing Data Faster with AI</h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto px-4">
            Migrating billing data can take months. AI tools can accelerate migration time by 90% while maintaining the highest quality standards.
          </p>
        </div>
      </section>

      {/* Section 1 - Light */}
      <section className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="flex-1 space-y-6">
              <h2 className="text-3xl font-bold">Migrate Billing Data with Confidence</h2>
              <p className="text-lg text-gray-600">
                Our AI-powered platform ensures accurate migration of customer accounts, subscription plans, payment methods, and billing history while maintaining data integrity.
              </p>
            </div>
            <div className="flex-1">
              <img
                src="/website-images/erp_migration_data_flow_1020_700.png"
                alt="Billing Migration Flow"
                className="rounded-lg shadow-lg w-full"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Section 2 - Dark */}
      <section className="bg-black text-white py-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row-reverse items-center gap-12">
            <div className="flex-1 space-y-6">
              <h2 className="text-3xl font-bold">Ensure the Highest Quality</h2>
              <p className="text-lg text-gray-300">
                Intelligent reconciliation which combines human input with AI automation ensures no billing information is lost.
              </p>
            </div>
            <div className="flex-1">
              <img
                src="/website-images/erp_reconiliation_1020_700.png"
                alt="Revenue Protection"
                className="rounded-lg shadow-lg w-full"
              />
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section - Dark */}
      <section className="bg-black text-white py-24">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {[
              {
                title: "Data Extraction",
                description: "Automatically extract billing data from your current system.",
                icon: Database
              },
              {
                title: "Data Transformation",
                description: "Map and transform billing records to your new system format.",
                icon: ArrowRightLeft
              },
              {
                title: "Incremental Migration",
                description: "Support for phased migration of billing accounts.",
                icon: RefreshCw
              },
              {
                title: "Reconciliation",
                description: "Combine human knowledge with AI automation.",
                icon: Shield
              }
            ].map((step, index) => (
              <div key={index} className="bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10">
                <step.icon className="w-12 h-12 text-[#8000FF] mx-auto mb-4" />
                <h3 className="text-lg font-semibold mb-2 text-center">{step.title}</h3>
                <p className="text-gray-300 text-sm text-center">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section - Purple Gradient */}
      <section className="bg-gradient-to-br from-[#8000FF] to-[#6000CC] text-white py-24">
        <div className="max-w-3xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Billing Migration?</h2>
          <p className="text-xl">
            Get in touch to learn how we can help protect your revenue during migration: {' '}
            <a href="mailto:info@doyen.com" className="hover:underline">
              info@doyen.com
            </a>
          </p>
        </div>
      </section>
    </div>
  );
}