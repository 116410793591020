import React from 'react';
import { SEO } from '../components/SEO';

export function TermsOfService() {
  return (
    <div className="min-h-screen pt-32 pb-16 bg-gray-50">
      <SEO
        title="Terms of Service | Doyen AI"
        description="Read about the terms and conditions for using Doyen AI services"
        url={`${window.location.origin}/terms-of-service`}
      />
      
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">Terms of Service</h1>
        <div className="prose prose-lg max-w-none">
          <p className="text-sm text-gray-500 mb-8">Effective Date: April 8, 2025</p>
          
          <p className="mb-8">
            Welcome to Doyen AI, Corp. ("Doyen," "we," or "us"). These Terms of Service ("Terms") govern your access to and use of our products, services, websites, and applications (collectively, the "Services"). By accessing or using the Services, you agree to be bound by these Terms.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">1. Use of Services</h2>
          <p>You may use the Services only in accordance with these Terms and all applicable laws. You agree to use the Services only for internal business purposes and not for resale or unauthorized redistribution.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">2. Account Registration</h2>
          <p>You must register for an account to access the Services. You are responsible for safeguarding your account credentials and for all activity that occurs under your account.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">3. Acceptable Use</h2>
          <p>You agree not to:</p>
          <ul className="list-disc ml-6 mb-4">
            <li>Use the Services for unlawful, harmful, or fraudulent activities</li>
            <li>Interfere with the security or integrity of the Services</li>
            <li>Access or attempt to access systems or data not intended for you</li>
            <li>Modify, copy, or reverse engineer any part of the platform</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8 mb-4">4. Customer Data</h2>
          <p>You retain all rights to data you submit to the platform ("Customer Data"). You grant Doyen a limited license to process Customer Data solely to provide and support the Services.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">5. Intellectual Property</h2>
          <p>Doyen retains all rights, title, and interest in and to the Services, including all associated intellectual property. Nothing in these Terms grants you ownership of any Doyen intellectual property.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">6. Termination</h2>
          <p>We may suspend or terminate your access to the Services at our discretion, including for violation of these Terms or misuse of the platform. Upon termination, your right to use the Services will cease immediately.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">7. Disclaimers</h2>
          <p>The Services are provided "as is" and "as available," without warranty of any kind. We do not guarantee the Services will be error-free or uninterrupted.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">8. Limitation of Liability</h2>
          <p>To the maximum extent permitted by law, Doyen shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Services.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">9. Governing Law</h2>
          <p>These Terms are governed by the laws of the Commonwealth of Massachusetts, without regard to conflict of laws principles.</p>

          <h2 className="text-2xl font-bold mt-8 mb-4">10. Contact</h2>
          <p>If you have questions about these Terms, please contact us at legal@doyen.com.</p>
        </div>
      </div>
    </div>
  );
}