import React from 'react';
import { SEO } from '../components/SEO';
import { ArrowRight, Settings, Users, Shield, Zap } from 'lucide-react';

export function Partners() {
  return (
    <div className="min-h-screen">
      <SEO
        title="Partner with Doyen AI | Reduce Migration Friction"
        description="Partner with Doyen AI to dramatically reduce migration friction and accelerate customer onboarding"
        url={`${window.location.origin}/partners`}
      />

      {/* Header Section */}
      <section className="bg-black text-white pt-32 pb-24">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <h1 className="text-5xl font-bold mb-8">Partner with Doyen</h1>
          <p className="text-xl text-gray-300">
            Accelerate customer time-to-value with customized AI solutions tailored to your platform
          </p>
        </div>
      </section>

      {/* Main Content */}
      <section className="py-24 bg-white">
        <div className="max-w-4xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              Custom Solutions for Your Platform
            </h2>
            <p className="text-xl text-gray-600">
              We work closely with your team to customize Doyen AI for your specific data migrations ensuring the highest quality migrations at the maximum velocity.
            </p>
          </div>

          {/* Features Grid */}
          <div className="grid md:grid-cols-2 gap-8 mb-16">
            {[
              {
                icon: Settings,
                title: "Customized Integration",
                description: "Our team works directly with yours to deeply understand your platform's data structures and migration requirements."
              },
              {
                icon: Users,
                title: "Universal Source Support",
                description: "Our AI is able to work with any source system, including spreadsheets and home-grown solutions, ensuring anyone can migrate to your platform."
              },
              {
                icon: Shield,
                title: "Quality Assurance",
                description: "Combine AI and human validation to ensure data accuracy while dramatically accelerating migration speed."
              },
              {
                icon: Zap,
                title: "10x Faster Data Migrations",
                description: "Reduce migration time from months to days with customized AI automation."
              }
            ].map((feature, index) => (
              <div key={index} className="bg-gray-50 rounded-xl p-8">
                <feature.icon className="w-8 h-8 text-[#8000FF] mb-4" />
                <h3 className="text-xl font-bold text-gray-900 mb-3">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>

          <div className="grid md:grid-cols-2 gap-12 mb-16">
            <div className="bg-gray-50 rounded-xl p-8">
              <h3 className="text-xl font-bold text-gray-900 mb-4">
                For Your Business
              </h3>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-start">
                  <ArrowRight className="w-5 h-5 text-[#8000FF] mr-2 flex-shrink-0 mt-1" />
                  <span>Reduce migration friction and accelerate sales cycles</span>
                </li>
                <li className="flex items-start">
                  <ArrowRight className="w-5 h-5 text-[#8000FF] mr-2 flex-shrink-0 mt-1" />
                  <span>Decrease implementation costs and resources</span>
                </li>
                <li className="flex items-start">
                  <ArrowRight className="w-5 h-5 text-[#8000FF] mr-2 flex-shrink-0 mt-1" />
                  <span>Enable customers to realize value faster</span>
                </li>
              </ul>
            </div>

            <div className="bg-gray-50 rounded-xl p-8">
              <h3 className="text-xl font-bold text-gray-900 mb-4">
                For Your Customers
              </h3>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-start">
                  <ArrowRight className="w-5 h-5 text-[#8000FF] mr-2 flex-shrink-0 mt-1" />
                  <span>Dramatically reduce migration time and effort</span>
                </li>
                <li className="flex items-start">
                  <ArrowRight className="w-5 h-5 text-[#8000FF] mr-2 flex-shrink-0 mt-1" />
                  <span>Maintain highest quality standards with AI assistance</span>
                </li>
                <li className="flex items-start">
                  <ArrowRight className="w-5 h-5 text-[#8000FF] mr-2 flex-shrink-0 mt-1" />
                  <span>Get to value realization faster</span>
                </li>
              </ul>
            </div>
          </div>

          {/* FAQ Section */}
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
            <div className="space-y-8">
              <div className="bg-gray-50 rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-3">How long does customization take?</h3>
                <p className="text-gray-600">
                  The customization timeline depends on the complexity of your platform and migration requirements. Typically, the customization process takes anywhere from 2 weeks to 2 months to complete.
                </p>
              </div>

              <div className="bg-gray-50 rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-3">What does customization entail?</h3>
                <p className="text-gray-600">
                  We are able to add custom information to Doyen unique to your platform, ensuring that our AI understands your specific data structures, business rules, and migration requirements.
                </p>
              </div>

              <div className="bg-gray-50 rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-3">Tell me about the overall customization process?</h3>
                <p className="text-gray-600">
                  We schedule regular calls with your team where we test various objects and data-sets while incrementing Doyen's knowledge of your platform. This iterative process ensures Doyen AI is tailored to your product.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-br from-[#8000FF] to-[#6000CC] text-white py-24">
        <div className="max-w-3xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Become a Partner</h2>
          <p className="text-xl mb-8">
            Ready to transform your customer migrations? Let's discuss how we can customize our solution for your platform.
          </p>
          <a 
            href="mailto:info@doyen.com" 
            className="inline-flex items-center px-6 py-3 border-2 border-white rounded-lg text-lg font-semibold hover:bg-white hover:text-[#8000FF] transition-colors"
          >
            Contact Us
          </a>
        </div>
      </section>
    </div>
  );
}