import React from 'react';
import { Shield, Lock, Database, FileCheck, Server, Users } from 'lucide-react';
import { SEO } from '../../components/SEO';

export function Security() {
  return (
    <div className="min-h-screen">
      <SEO
        title="Security & Compliance | Doyen AI"
        description="Enterprise-grade security measures ensuring your data stays protected"
        url={`${window.location.origin}/products/security`}
      />

      {/* Header Section */}
      <section className="bg-black text-white pt-32 pb-24">
        <div className="text-center">
          <h1 className="text-5xl font-bold mb-6">Enterprise-Grade Security</h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto px-4">
            Your data security is our top priority. We implement comprehensive security measures to ensure your information remains protected at all times.
          </p>
        </div>
      </section>

      {/* Security Features Grid */}
      <section className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Tenant Isolation",
                description: "Complete isolation between customer environments ensures data separation and security.",
                icon: Users
              },
              {
                title: "Data Encryption at Rest",
                description: "All stored data is encrypted using industry-standard AES-256 encryption.",
                icon: Database
              },
              {
                title: "Encryption in Transit",
                description: "TLS 1.3 encryption for all data transfers ensures secure communication.",
                icon: Lock
              },
              {
                title: "Role-Based Access Control",
                description: "Access control with customizable roles and permissions.",
                icon: Shield
              },
              {
                title: "Secure Infrastructure",
                description: "Cloud infrastructure with multiple layers of security controls.",
                icon: Server
              },
              {
                title: "Compliance Standards",
                description: "Regular security audits and compliance with industry standards.",
                icon: FileCheck
              }
            ].map((feature, index) => (
              <div key={index} className="bg-white rounded-xl p-6 shadow-lg border border-gray-100">
                <feature.icon className="w-12 h-12 text-[#8000FF] mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Security Commitment Section */}
      <section className="bg-gray-50 py-24">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Our Security Commitment</h2>
            <p className="text-lg text-gray-600 max-w-3xl mx-auto">
              We understand the critical nature of your enterprise data. Our platform is built with security-first principles to ensure your data remains protected and private.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <div className="bg-white rounded-xl p-8 shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Data Privacy</h3>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Shield className="w-6 h-6 text-[#8000FF] mr-3 flex-shrink-0 mt-1" />
                  <p className="text-gray-600">Dedicated infrastructure for each customer ensures complete data isolation</p>
                </li>
                <li className="flex items-start">
                  <Lock className="w-6 h-6 text-[#8000FF] mr-3 flex-shrink-0 mt-1" />
                  <p className="text-gray-600">End-to-end encryption for all data processing and storage</p>
                </li>
                <li className="flex items-start">
                  <Users className="w-6 h-6 text-[#8000FF] mr-3 flex-shrink-0 mt-1" />
                  <p className="text-gray-600">Role-based access control (RBAC) with granular permissions and audit logging</p>
                </li>
              </ul>
            </div>

            <div className="bg-white rounded-xl p-8 shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Data Protection</h3>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Database className="w-6 h-6 text-[#8000FF] mr-3 flex-shrink-0 mt-1" />
                  <p className="text-gray-600">Regular security audits and penetration testing</p>
                </li>
                <li className="flex items-start">
                  <Server className="w-6 h-6 text-[#8000FF] mr-3 flex-shrink-0 mt-1" />
                  <p className="text-gray-600">Continuous monitoring and threat detection</p>
                </li>
                <li className="flex items-start">
                  <FileCheck className="w-6 h-6 text-[#8000FF] mr-3 flex-shrink-0 mt-1" />
                  <p className="text-gray-600">Automated backup and disaster recovery procedures</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="bg-white py-24">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Security FAQ</h2>
          <div className="space-y-8">
            <div className="bg-gray-50 rounded-xl p-6">
              <h3 className="text-xl font-semibold mb-3">Do you share data between customers?</h3>
              <p className="text-gray-600">
                No, we never share data between customers. Each customer's data is strictly isolated in their own dedicated environment, and we maintain complete separation between customer instances.
              </p>
            </div>

            <div className="bg-gray-50 rounded-xl p-6">
              <h3 className="text-xl font-semibold mb-3">Is customer data used for training AI models?</h3>
              <p className="text-gray-600">
                No, we do not use any customer data for training AI models. Your data remains exclusively yours and is only used to provide the services you've specifically requested.
              </p>
            </div>

            <div className="bg-gray-50 rounded-xl p-6">
              <h3 className="text-xl font-semibold mb-3">Is PII data sent to third-party LLMs?</h3>
              <p className="text-gray-600">
                No, we never send any personally identifiable information (PII) to third-party hosted LLMs. All PII data is processed within your dedicated environment and remains under your control at all times.
              </p>
            </div>

            <div className="bg-gray-50 rounded-xl p-6">
              <h3 className="text-xl font-semibold mb-3">How do you handle data encryption?</h3>
              <p className="text-gray-600">
                We implement industry-standard encryption both at rest and in transit. All data is encrypted using AES-256 encryption at rest, and all data transfers occur over TLS 1.3 encrypted connections.
              </p>
            </div>

            <div className="bg-gray-50 rounded-xl p-6">
              <h3 className="text-xl font-semibold mb-3">What security certifications do you maintain?</h3>
              <p className="text-gray-600">
                We maintain rigorous security standards and undergo regular third-party security audits. Our infrastructure and processes are designed to meet enterprise security requirements.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-gradient-to-br from-[#8000FF] to-[#6000CC] text-white py-24">
        <div className="max-w-3xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Learn More About Our Security Measures?</h2>
          <p className="text-xl mb-8">
            Contact us to discuss your security requirements and learn more about our enterprise-grade security features.
          </p>
          <a 
            href="mailto:security@doyen.com" 
            className="inline-flex items-center px-6 py-3 border-2 border-white rounded-lg text-lg font-semibold hover:bg-white hover:text-[#8000FF] transition-colors"
          >
            Contact Security Team
          </a>
        </div>
      </section>
    </div>
  );
}