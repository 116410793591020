import React from 'react';
import { CheckCircle } from 'lucide-react';
import { SEO } from '../components/SEO';

interface SystemStatus {
  status: string;
}

export function Status() {
  return (
    <div className="min-h-screen pt-32 pb-16 bg-gray-50">
      <SEO
        title="System Status | Doyen AI"
        description="Current system status and uptime information for Doyen AI services"
        url={`${window.location.origin}/status`}
      />
      
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">System Status</h1>
        
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8 mb-8">
          <div className="flex items-center mb-6">
            <CheckCircle className="w-8 h-8 text-green-500 mr-3" />
            <div>
              <h2 className="text-2xl font-semibold text-gray-900">All Systems Operational</h2>
            </div>
          </div>
          
          <div className="space-y-4">
            <div className="flex items-center justify-between py-4 border-t border-gray-100">
              <div>
                <h3 className="font-medium text-gray-900">Core Services</h3>
                <p className="text-sm text-gray-500">AI Processing & Workflows</p>
              </div>
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                Operational
              </span>
            </div>
            
            <div className="flex items-center justify-between py-4 border-t border-gray-100">
              <div>
                <h3 className="font-medium text-gray-900">Web Application</h3>
                <p className="text-sm text-gray-500">User Interface & Dashboard</p>
              </div>
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                Operational
              </span>
            </div>
            
            <div className="flex items-center justify-between py-4 border-t border-gray-100">
              <div>
                <h3 className="font-medium text-gray-900">Authentication</h3>
                <p className="text-sm text-gray-500">User Authentication Services</p>
              </div>
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                Operational
              </span>
            </div>
          </div>
        </div>
        
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">System Metrics</h2>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-gray-600">Uptime (30 days)</span>
              <span className="font-medium text-gray-900">99.99%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}